import React from "react";
import DuaV1 from "@Zakat/components/Payment";
import DuaV2 from "./duaV2";
import { isZakat2025Enabled } from "@helpers/useFeatureFlag";

const Dua: React.FC = () => {
  return isZakat2025Enabled ? <DuaV2 /> : <DuaV1 />;
};

export default Dua;
